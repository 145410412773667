import React from "react";
import Container from "../components/container";
import Footer from "../components/footer";
import Router from "next/router";
import { fetchRequest ,Apiversion} from "../config/Fatch.config";
import { ApiPort , CMSURL } from "../actions/API";
import setupTraces from "../config/trace";
import { checkAffQueryString, Cookie } from '../actions/util';

const pageName = "m3-002"; //寫死，獲得最大效能
setupTraces(pageName);

export default class Sqrp extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mainSiteUrl:'',
            liveChatUrl :'',
            nativeAppDownloadUrl:'',
            affiliateUrlLM:'',
            bannerList:[],
            affiliateValue: '', // To store the affiliate value
        }
        this.addAffiliateParamToUrl = this.addAffiliateParamToUrl.bind(this)
    }


    componentDidMount(){
        // 获取URL 参数
        checkAffQueryString(global.location.search);
        this.QrpLoad();
        this.AffiliateLink();
        this.getBanner();

         // Fetch the affiliate value from the cookie after the component mounts
         const affiliateValue = Cookie.GetCookieKeyValue('CO_affiliate');
         this.setState({ affiliateValue });
    }

     // Function to add the 'aff' parameter to the URL if the 'CO_affiliate' cookie is present
		 addAffiliateParamToUrl(url) {
			const { affiliateValue } = this.state;
			if (affiliateValue) {
				try {
					let urlObj = new URL(url, window.location.origin); // Use base URL for relative paths
					urlObj.searchParams.set('aff', affiliateValue);
					return urlObj.toString();
				} catch (error) {
					console.error("Invalid URL:", url);
				}
			}
			return url;
		}

    async getBanner() {
        let data = await fetchRequest(ApiPort.Banner2, 'GET');
        try {
            if (data) {
                if (data.length > 0) {
                    this.state.bannerList = data.map((val, index) => {
                        return { cmsImageUrlWeb: CMSURL + val.cmsImageUrlWeb, cmsImageUrlMobile: CMSURL + val.cmsImageUrlMobile , cmsImageUrlMobileAlt : val.cmsImageUrlMobileAlt , cmsImageUrlWebAlt : val.cmsImageUrlWebAlt}
                    });
                }
            }
        } catch (error) {

        }
    }

    async QrpLoad() {

        let data = await fetchRequest(ApiPort.QrpLoad, 'GET', '', Apiversion);
        console.log(data);
        if(data.isSuccess){
            this.setState({
                mainSiteUrl : data.mainSiteUrl,
                liveChatUrl :  data.liveChatUrl,
                nativeAppDownloadUrl : data.nativeAppDownloadUrl,
                affiliateUrlLM: data.affiliateUrlLM
            })
        }
    }

    async AffiliateLink(){
        const affCode = Cookie.GetCookieKeyValue('CO_affiliate') ?  '&affiliateCode=' + Cookie.GetCookieKeyValue('CO_affiliate') : '';
        let Apiversion = '?api-version=1.0&brand=JBO&Platform=Android' + affCode;
        let data = await fetchRequest(ApiPort.AffiliateLink , 'GET' , '' , Apiversion);
        console.log('data==',data);
        if(data.isSuccess){
            this.setState({
                nativeAppUrl:data.nativeAppUrl
            })
        }
    }

    closeModal(){
        this.setState({
            isModal : false
        })
    }

    goHome(){
        let { mainSiteUrl } = this.state;
        global.location.href = this.addAffiliateParamToUrl(mainSiteUrl);
    }

    render(){
        let { nativeAppUrl , liveChatUrl , mainSiteUrl} = this.state;
        return(
            <div className={pageName}>
                {/* loginButton */}
                <div className="loginInButton" onClick={()=>{
                    this.goHome()
                }}></div>
                {/* Loginbackground */}
                <Container mainSiteUrl={mainSiteUrl} nativeAppUrl={nativeAppUrl} liveChatUrl = { liveChatUrl } bannerList={this.state.bannerList} addAffiliateParamToUrl={this.addAffiliateParamToUrl}></Container>
                {/* Footer */}
                <Footer mainSiteUrl={mainSiteUrl} addAffiliateParamToUrl={this.addAffiliateParamToUrl}></Footer>



            </div>
        )

    }
}

